"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const appModule = ng.module('app');
class ConnectfourCtrl {
    constructor($scope) {
        this.$scope = $scope;
    }
    $onInit() {
    }
}
ConnectfourCtrl.$inject = ['$scope'];
appModule.component('gameConnectfour', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ConnectfourCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('connectfour/');
    }]);
